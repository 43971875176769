import React, { useRef, useState, useEffect } from 'react'
import Footer from '../components/Footer'
import Spinner from '../components/Spinner'
import Navbar from '../components/Navbar'
import Legend from '../components/Legend'
import { treatmentData } from '../data/treatmentData'
import { procedureData } from '../data/procedureData'
import { useLocation } from "react-router-dom"
import AppointmentCalendar from '../components/AppointmentCalendar'
import emailjs from '@emailjs/browser';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { format } from 'date-fns';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


// Change Number of Days for Future Appointments
const dateLength = 60
const dateList = []

for (var i = 1; i < dateLength; i++) {
    dateList.push(i)
}

const InClinicStartTime = [9, 0]
const InClinicEndTime = [15, 0]

const OnlineStartTime = [3, 0]
const OnlineEndTime = [9, 0]

const InClinicDuration = 60
const OnlineDuration = 30

// Change holidays
const holidayList = ["Fri", "Sep 23", "Sep 24", "Sep 25"]


export default function Appointment() {

    const form = useRef();

    const [open, setOpen] = useState(false);
    const [submitRes, setSubmitRes] = useState(false)
    const location = useLocation()
    const [category, setCategory] = useState('');
    const [treatment, setTreatment] = useState('');
    const [procedure, setProcedure] = useState('')
    const [appointmentType, setAppointmentType] = useState("None")
    const [treatmentList, setTreatmentList] = useState([])
    const [procedureList, setProcedureList] = useState([])
    const [selectedDay, setSelectedDay] = useState(new Date());
    const categories = treatmentData.map((item) => {
        return item["category"]
    })

    const availableTimeslots = dateList.map((id) => {

        // holidaycheck 
        const d = new Date(new Date(new Date().setDate(new Date().getDate() + id))).toString()

        var holiday = false

        for (var i = 0; i < holidayList.length; i++) {
            if (d.includes(holidayList[i])) {
                holiday = true
            }
        }

        if (!holiday) {

            // no appointment type selected
            if (appointmentType === "None") {
                return {
                    id,
                    startTime: new Date(new Date(new Date().setDate(new Date().getDate() + id)).setHours(0, 0, 0, 0)),
                    endTime: new Date(new Date(new Date().setDate(new Date().getDate() + id)).setHours(0, 0, 0, 0)),
                };
            }

            // In Clinic Appointment
            else if (appointmentType === "In-Clinic") {
                return {
                    id,
                    startTime: new Date(new Date(new Date().setDate(new Date().getDate() + id)).setHours(InClinicStartTime[0], InClinicStartTime[1], 0, 0)),
                    endTime: new Date(new Date(new Date().setDate(new Date().getDate() + id)).setHours(InClinicEndTime[0], InClinicEndTime[1], 0, 0)),
                };
            }

            // Online Appointment
            else if (appointmentType === "Online") {
                return {
                    id,
                    startTime: new Date(new Date(new Date().setDate(new Date().getDate() + id)).setHours(OnlineStartTime[0], OnlineStartTime[1], 0, 0)),
                    endTime: new Date(new Date(new Date().setDate(new Date().getDate() + id)).setHours(OnlineEndTime[0], OnlineEndTime[1], 0, 0)),
                };
            }

        } else {
            return undefined
        }

    }).filter((item) => { return item !== undefined });

    useEffect(() => {
        if (location.state.category && location.state.category !== '') {
            setCategory(location.state.category)
            onCategorySelect(location.state.category)
        }

        if (location.state.treatment && location.state.treatment !== '') {
            setTreatment(location.state.treatment)
            onTreatmentSelect(location.state.category, location.state.treatment)
        }

        if (location.state.procedure && location.state.procedure !== '') {
            setProcedure(location.state.procedure)
        }


    }, [location.state.category, location.state.treatment, location.state.procedure])

    const handleChange = (event) => {
        switch (event.target.id) {
            case "Category":
                setCategory(event.target.value)
                onCategorySelect(event.target.value)
                break
            case "Treatment":
                setTreatment(event.target.value)
                onTreatmentSelect(category, event.target.value)
                break
            case "Procedure":
                setProcedure(event.target.value)
                break
            case "Appointment":
                setAppointmentType(event.target.value)
                break
        }
    }

    const onCategorySelect = (value) => {
        setTreatment('');

        var filteredList = treatmentData.map((item) => {
            if (item["category"] === value) {
                var list = []
                for (var i = 0; i < item["treatments"].length; i++) {
                    list.push({ name: item["treatments"][i]["name"] })
                }
                return list;
            }
        }).filter(item => { return item })

        setTreatmentList(filteredList[0])
    }

    const onTreatmentSelect = (category, value) => {
        setProcedure('')

        var filteredList = procedureData.map((item) => {
            var list = []
            if (item["category"] === category) {

                for (var i = 0; i < item["procedures"].length; i++) {
                    if (item["procedures"][i]["treatment"] === value) {
                        list.push({ name: item["procedures"][i]["name"] })
                    }
                }
            }
            return list

        }).filter(item => { return item.length > 0 })

        if (filteredList.length > 0) {
            setProcedureList(filteredList[0])
        } else {
            setProcedure(filteredList)
        }

    }

    const handlePopupClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_APPOINTMENT_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
            .then((result) => {
                e.target.reset()
                setCategory('')
                setTreatment('')
                setProcedure('')
                setAppointmentType('0')
                setTreatmentList([])
                setProcedureList([])
                setSelectedDay(new Date())
                setSubmitRes(true)
                setOpen(true)

            }, (error) => {
                setSubmitRes(false)
                setOpen(true)
            });
    };

    return (
        <div>
            <Spinner />
            <Navbar />
            <Legend pageName1="Book Now" pageName2="Appointment" />

            {/* Appointment Form Start */}
            <div className="container-fluid bg-appointment bg-secondary my-5 wow fadeInUp" data-wow-delay="0.1s"
                >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mt-5 mb-5">
                            <div className="appointment-form h-100 d-flex flex-column p-5 justify-content-center wow zoomIn" data-wow-delay="0.6s">
                                <h1 className="text-black mb-4 text-center">Make An Appointment</h1>

                                <form ref={form} onSubmit={sendEmail}>
                                    <div className="row g-3 ">

                                        {/* Patient Info */}
                                        <div className="col-12" style={{ display: "inline-block", textAlign: "left" }}>
                                            <h6 className='text-white'>Name:</h6>
                                            <input name="userName" type="text" className="form-control bg-light border-0" placeholder="Enter Your Name" style={{ height: "55px" }} required />
                                        </div>
                                        <div className="col-12" style={{ display: "inline-block", textAlign: "left" }}>
                                            <h6 className='text-white'>Email:</h6>
                                            <input name="userEmail" type="email" className="form-control bg-light border-0" placeholder="Enter Your Email" style={{ height: "55px" }} required />
                                        </div>
                                        <div className="col-12" style={{ display: "inline-block", textAlign: "left" }}>
                                            <h6 className='text-white'>Phone Number:</h6>
                                            <input name="userNumber" type="tel" className="form-control bg-light border-0" placeholder="Enter Your Number" style={{ height: "55px" }} required pattern="^[0-9]+$" />
                                        </div>

                                        {/* Category */}
                                        <div className="col-12" style={{ display: "inline-block", textAlign: "left" }}>
                                            <h6 className='text-white'>Category:</h6>
                                            <select id='Category' name="userCategory" value={category} onChange={handleChange} className="form-select bg-light border-0" style={{ height: "55px" }} required>
                                                <option value='' defaultValue disabled>Select A Category</option>
                                                {
                                                    categories.map((item, index) => {
                                                        return (<option value={item} key={index}>{item}</option>)
                                                    })
                                                }
                                            </select>
                                        </div>

                                        {/* Treatment */}
                                        <div className="col-12" style={{ display: "inline-block", textAlign: "left" }}>
                                            <h6 className='text-white'>Treatment Type:</h6>
                                            <select id='Treatment' name="userTreatment" value={treatment} onChange={handleChange} className="form-select bg-light border-0" style={{ height: "55px" }} required>
                                                <option value='' defaultValue disabled>Select Treatment</option>
                                                {
                                                    treatmentList.map((item, index) => {
                                                        return <option value={item.name} key={index}>{item.name}</option>

                                                    })
                                                }
                                            </select>

                                        </div>

                                        {/* Procedures */}
                                        <div className="col-12" style={{ display: "inline-block", textAlign: "left" }}>
                                            <h6 className='text-white'>Procedure Type:</h6>
                                            <select id='Procedure' name="userProcedure" value={procedure} onChange={handleChange} className="form-select bg-light border-0" style={{ height: "55px" }}>
                                                <option value='' defaultValue >General Check-up</option>
                                                {

                                                    procedureList.map((item, index) => {
                                                        return <option value={item.name} key={index}>{item.name}</option>
                                                    })
                                                }

                                            </select>

                                        </div>


                                        {/* Appointment Type */}
                                        <div className="col-12" style={{ display: "inline-block", textAlign: "left" }}>
                                            <h6 className='text-white'>Appointment Type:</h6>
                                            <select id='Appointment' name="userAppointmentType" value={appointmentType} onChange={handleChange} className="form-select bg-light border-0" style={{ height: "55px" }} required>
                                                <option value="None" defaultValue disabled>Select Appointment Type</option>
                                                <option value="In-Clinic">In-Clinic</option>
                                                <option value="Online">Online</option>
                                            </select>
                                        </div>

                                        {/* Calendar */}

                                        <div className="col-12" style={{ display: "inline-block", textAlign: "left" }}>
                                            <h6 className='text-white'>Date and Time:</h6>
                                            <input name='userDate' className="form-control bg-light border-0" style={{ height: "55px", marginBottom: "10px" }}
                                                required
                                                readOnly
                                                value={
                                                    format(selectedDay, " dd LLLL yyyy") === format(new Date(), " dd LLLL yyyy") ?
                                                        "Please Select a Date"
                                                        :
                                                        format(selectedDay, " dd LLLL yyyy")
                                                } />

                                            <AppointmentCalendar
                                                eventDurationInMinutes={appointmentType === "2" ? InClinicDuration : OnlineDuration}
                                                availableTimeslots={availableTimeslots}
                                                selectedDay={selectedDay}
                                                setSelectedDay={setSelectedDay}
                                            />
                                        </div>

                                        {/* Submit */}
                                        <div className="col-12" id="Submit">
                                            <button className="btn btn-dark w-100 py-3" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Appointment Form End */}

            {submitRes ?
                <Snackbar open={open} autoHideDuration={6000} onClose={handlePopupClose}>
                    <Alert onClose={handlePopupClose} severity="success" sx={{ width: '100%' }}>
                        Booking Sent! We will contact you shortly
                    </Alert>
                </Snackbar>
                :
                <Snackbar open={open} autoHideDuration={6000} onClose={handlePopupClose}>
                    <Alert onClose={handlePopupClose} severity="error" sx={{ width: '100%' }}>
                        Failed! Please Try Again
                    </Alert>
                </Snackbar>}


            <Footer />
        </div>
    )
}
