import React from 'react'
import Footer from '../components/Footer'
import Legend from '../components/Legend'
import Navbar from '../components/Navbar'
import Spinner from '../components/Spinner'

export default function About () {

    return (
        <>
            <Spinner />
            <Navbar />
            <Legend pageName1="About Us" pageName2="About" />

            {/* About Us Start */}
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row g-5">

                        {/* Clinic Introduction */}
                        <div className="col-lg-12 col-xs-12 ">
                            <div className="section-title mb-4">
                                <h5 className="position-relative d-inline-block text-primary text-uppercase">About Us</h5>
                                <h1 className="display-5 mb-0">Dermatology You Can Trust</h1>
                            </div>
                            <h4 className="text-body fst-italic mb-4">Lulu Derma is an exclusively women's only clinic.</h4>
                            <p className="mb-4">
                                It's a judgement free, safe space for women to consult their doctor about all skin related issues.
                                <br />
                                <br />
                                Dr. Soha Khan conceptualized it's establishment with the numerous women in mind who are snubbed by
                                their family members, societal construes, or misinformation and aren't allowed to discuss medical
                                issues that concern them.
                                <br />
                                <br />
                                We are a small, growing clinic, but we take all precautions to maintain international safety and
                                hygiene standards. Our staff is well trained and always giving their best to provide you with as
                                good an experience as possible.
                                <br />
                                <br />
                                Over the past 20 months, we've treated hundreds of women, young and old, independent and dependent,
                                and with their support, have developed into the only exclusive women's skin clinic in Hyderabad.
                            </p>
                            <div className="row g-3">
                                <div className="col-sm-12 wow zoomIn" data-wow-delay="0.3s">
                                    <h5 className="mb-3"><i className="fa fa-check-circle text-primary me-3"></i>Award Winning</h5>
                                    <h5 className="mb-3"><i className="fa fa-check-circle text-primary me-3"></i>Professional Staff</h5>
                                    <h5 className="mb-3"><i className="fa fa-check-circle text-primary me-3"></i>Fair Prices</h5>
                                </div>
                            </div>
                        </div>

                        {/* Dr Soha's Profile Pic */}
                        <div className="col-sm-6 col-xs-12" style={{ minHeight: "200px" }}>
                            <div className="position-relative h-100">
                                <img className=" w-100 wow zoomIn" alt='' data-wow-delay="0.9s" src="assets/img/about.jpg" style={{ borderRadius: "50%" }} />
                            </div>
                        </div>

                        {/* About Dr Soha */}
                        <div className="col-sm-6 col-xs-12 ">
                            <div className="section-title mb-4">
                                <h1 className="display-5 mb-0">Meet Dr. Soha Khan</h1>
                            </div>
                            <h4 className="text-body fst-italic mb-4">MBBS, DDVL</h4>
                            <p className="mb-4">
                                Dr. Soha Khan is a board-certified dermatologist and trichologist who specializes in Cosmetology and Dermatosurgery.
                                <br />
                                <br />
                                She completed MBBS from Shadan Institute of Medical Sciences, Hyderabad, and Post-graduation in Dermatology from the
                                prestigious Deccan College of Medical Sciences, Hyderabad.
                                <br />
                                <br />
                                She has had the experience of handling large volumes of clinical outpatient and inpatient dermatology cases in both
                                Owaisi and Princess Esra hospitals and the ability to deliver effective treatment for timely and full recoveries.
                            </p>
                        </div>
                        <div>
                        </div>

                    </div>
                </div>
            </div>
            {/* About Us End */}

            <Footer />

        </>
    )
}
