import React, { useState } from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Spinner from '../components/Spinner'
import { Link } from 'react-router-dom'
import { treatmentData } from '../data/treatmentData'
import { procedureData } from '../data/procedureData'
import BookNow from '../components/BookNow'

export default function Home () {

    const [selectProcedure, setSelectProcedure] = useState()

    var items = treatmentData.map((cat) => {
        var list = []
        for (var i = 0; i < cat.treatments.length; i++) {
            var li = {
                category: cat.category,
                name: cat.treatments[i].name,
                description: cat.treatments[i].description,
                image: cat.treatments[i].image
            }
            list.push(li)
        }

        return list
    })

    items = items[0].concat(items[1])

    var procedures = procedureData.map((item) => {
        var li = [];
        for (var i = 0; i < item.procedures.length; i++) {
            li.push(item.procedures[i].name)

        }

        return li

    }).filter((item) => {
        return item != null
    })

    procedures = procedures[0].concat(procedures[1])


    const handleSelectClick = (event) => {
        var value = JSON.stringify(event.target.value).replace(/ /g, "-").replace(/"/g, "")

        setSelectProcedure(value)
    }
    return (
        <>
            <Navbar />
            <Spinner />

            {/* Carousel Start --> */}
            <div className="container-fluid p-0">
                <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="w-100" src="assets/img/carousel1.jpg" alt="" />
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{ maxWidth: "900px" }}>
                                    <h5 className="text-white text-uppercase mb-3 animated slideInDown">Keep Your Skin Healthy</h5>
                                    <h1 className="display-1 text-white mb-md-4 animated zoomIn">Get The Best Quality Skin Treatment</h1>
                                    <BookNow className="btn btn-primary py-2 px-4 animated slideInLeft" type="Appointment" />
                                    <Link to="/contact" className="btn btn-secondary py-2 px-5 ms-3 animated slideInRight">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item ">
                            <img className="w-100" src="assets/img/carousel2.jpg" alt="" />
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{ maxWidth: "900px" }}>
                                    <h5 className="text-white text-uppercase mb-3 animated slideInDown">Keep Your Hair Healthy</h5>
                                    <h1 className="display-1 text-white mb-md-4 animated zoomIn">Get The Best Quality Hair Treatment</h1>
                                    <BookNow className="btn btn-primary py-2 px-4 animated slideInLeft" type="Appointment" />
                                    <Link to="/contact" className="btn btn-secondary py-2 px-5 ms-3 animated slideInRight">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img className="w-100" src="assets/img/carousel3.jpg" alt="" />
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{ maxWidth: "900px" }}>
                                    <h5 className="text-white text-uppercase mb-3 animated slideInDown">We Offer Laser Treatment</h5>
                                    <h1 className="display-1 text-white mb-md-4 animated zoomIn">Get The Best Quality Laser Treatments</h1>
                                    <BookNow className="btn btn-primary py-2 px-4 animated slideInLeft" type="Appointment" />
                                    <Link to="/contact" className="btn btn-secondary py-2 px-5 ms-3 animated slideInRight">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                        data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                        data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            {/* Carousel End --> */}


            {/* Banner Start --> */}
            <div className="container-fluid banner mb-5 mt-5">
                <div className="container">
                    <div className="row gx-0">

                        {/* Visit Us */}
                        <div className="col-lg-6 wow zoomIn" data-wow-delay="0.1s">
                            <div className="bg-primary d-flex flex-column p-5" style={{ height: "300px" }}>
                                <h3 className="text-white mb-3">Visit Us</h3>
                                <div className="d-flex justify-content-between text-white mb-3">
                                    <h6 className="text-white mb-0">Sat - Thu</h6>
                                    <p className="mb-0"> 11:00am - 7:00pm</p>
                                </div>
                                <div className="d-flex justify-content-between text-white mb-3">
                                    <h6 className="text-white mb-0">Friday </h6>
                                    <p className="mb-0"> Closed</p>
                                </div>
                                <div className="d-flex justify-content-between text-white mb-3">
                                    <h6 className="text-white mb-0">Online</h6>
                                    <p className="mb-0"> 7:00pm - 7:30pm</p>
                                </div>
                            </div>
                        </div>

                        {/* Search A Procedure */}
                        <div className="col-lg-6 wow zoomIn " data-wow-delay="0.3s">
                            <div className="bg-dark d-flex flex-column p-5 align-items-center" style={{ height: "300px" }}>
                                <h3 className="text-white mb-3">Find A Procedure</h3>
                                <select className="form-select bg-light border-0 mb-3" onChange={handleSelectClick} style={{ height: "40px" }} >
                                    <option defaultValue>Select A Procedure</option>
                                    {
                                        procedures.map((item, index) => {
                                            return <option value={item} key={index}>{item}</option>
                                        })
                                    }
                                </select>
                                <Link to={"/procedures/" + selectProcedure}><button className="btn btn-light" href="">Search</button></Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* Banner Start --> */}

            {/* Treatment Start --> */}
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">

                    {/* Procedures */}
                    <div className="row g-5 mb-5">
                        <div className="col-lg-12">
                            <div className="section-title mb-5" >
                                <h5 className="position-relative d-inline-block text-primary text-uppercase">Our Treatments</h5>
                                <h1 className="display-5 mb-0">We Offer The Best Quality Treatments</h1>
                            </div>
                            <div className="row g-5 float-left">
                                {
                                    items.map((item, index) => {
                                        return <h5 key={index} style={{ marginBottom: "0px", paddingBottom: "0px", marginTop: "5px" }}>{item.name}</h5>
                                    })
                                }

                                <Link to={"/treatments"}>Learn More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Treatment End --> */}

            <Footer />

        </>
    )
}
