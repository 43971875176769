import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './Home'
import About from './About'
import Appointment from './Appointment'
import Contact from './Contact'
import Treatments from "./Treatments"
import Testemonials from '../components/Testemonials'
import Procedures from './Procedures'
import ProcedureItem from './ProcedureItem'
import ScrollToTop from '../components/ScrollToTop'

export default function RouterPage() {
  return (
    <div>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/appointment' element={<Appointment />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/treatments' element={<Treatments />} />
          <Route path='/testemonials' element={<Testemonials />} />
          <Route path='/procedures' element={<Procedures />} />
          <Route path='/procedures/:procedure_name' element={<ProcedureItem />} />


        </Routes>
      </Router>
    </div>
  )
}
