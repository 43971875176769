export const procedureData = [
    {
        category: "Skin",
        procedures: [
            //  Acne / Pimples / Comedones"
            {
                name: "Chemical Peels for Acne",
                short: "IPL",
                treatment: "Acne / Pimples / Comedones",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Carbon Laser Toning (Hollywood Laser)",
                short: "Carbon Laser Toning (Hollywood Laser)",
                treatment: "Acne / Pimples / Comedones",
                sessions: "4-8 (Once per Month)",
                top: "30-40 minutes",
                description:
                    <p>
                        The carbon laser stimulates the collagen production in the skin, which helps in getting plumper and firm skin with a glow.
                        It removes build up of oil and dirt and can kill the acne causing bacteria and helps in shrinking the sebaceous glands.
                        <br></br>
                        <br></br>
                        It has no down time. It is also called a lunch break laser as you can resume your regular actrivities immediately afterwards.
                    </p>,

                pre:
                    <ul>
                        <li><i>Please inform your dermatologist about any cosmetic procedures done in the last 2 months, especially dermal filler / toxin (botox) / skin boosters.
                        </i></li>
                        <li>Cleansing</li>
                        <li>Degreasing</li>
                        <li>Application of Carbon Peel</li>
                    </ul>,
                pro:
                    <p>
                        A non oblative laser is used on the carbon peel to painlessly exfoliate and rejuvenate the skin.
                    </p>,

                post:
                    <ul>
                        <li>wait for 2 weeks before getting a dermal filler or toxin treatment</li>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "LED Light Therapy",
                short: "LED Light Therapy",
                treatment: "Acne / Pimples / Comedones",
                sessions: "Once a week / Along with medifacial procedures",
                top: "5-15 minutes",
                description:
                    <p>
                        LED Light Therapy is a harmless and painless procedure is used to promote anti-aging and acne.
                        it includes red and blue light.
                        <br></br> <br></br>
                        Red light stimulates collagen, reduces inflamation and improves circulation which gives you a healthy glow.
                        <br></br>
                        Blue light targets the oil glands, reducing oily skin and acne.
                        <br></br> <br></br>
                        It is safe for all skin colors and types.
                        it improves the skin <b>over time</b>.
                    </p>,

                pre:
                    <ul>
                        <li>Cleansing</li>
                        <li>Applying chemical peels (if recommended by the doctor)</li>
                    </ul>,
                pro:
                    <p>
                        The Skin is exposed to the laser from 5-10 minutes depending on the condition.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },

            // Pigmentation
            {
                name: "Chemical Peels for Pigmentation",
                short: "Chemical Peels",
                treatment: "Pigmentation",
                sessions: "6-10 (Once per Month)",
                top: "15 minutes",
                description:
                    <p>
                        There are 32+ types of chemical peels used for a range of issues like acne, pigmentation, melasma, anti aging, rejuvenation, skin glow, etc.

                    </p>,

                pre:
                    <ul>
                        <li>Cleansing</li>
                        <li>Degreasing</li>
                        <li>Do not use any active ingredients 1 day prior to procedure</li>
                        <li><i>If you are on any medication or have an active infection, please inform the doctor</i></li>
                    </ul>,
                pro:
                    <p>
                        Its an In-Clinic procedure, where a medication if applied on the target area for a duration of 10-15 minutes based on the type of peel and skin concern.

                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <li>Avoid active ingredients for 3 days</li>
                        <li>Do not apply make up or homecare remedies while on this treatment</li>
                        <li>Do not apply bleach for a few days</li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: " Q Switch Laser",
                short: "Q-Switch Laser",
                treatment: "Pigmentation",
                sessions: "2-4 (Once a Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        Q-Switch Laser is useful in treating various skin ailments and gives a bleached effect to the skin.
                    </p>,

                pre:
                    <ul>
                        <li>Cleansing</li>
                        <li>Degreasing</li>
                        <li>Do not use any active ingredients 1 day prior to procedure</li>
                        <li><i>If you are on any medication or have an active infection <b>ESPECIALLY connective tissue disorders like Lupus (SLE), Rheumatoid Arthiritis, Scleroderma, etc</b>, please inform the doctor.</i></li>
                    </ul>,
                pro:
                    <p>
                        A Laser beam of light is ussed to break down the pigments which are released into the skin and are desposed naturally by the body.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <li>you my experience redness and slight irritation</li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "IPL Laser",
                short: "IPL",
                treatment: "Pigmentation",
                sessions: "4-6 (Once per Month)",
                top: "20-30 minutes",
                description:
                    <p>
                        Intense Pulsed Light Laser is used to reduce pigmentation left behind by acne.
                        However it is not useful for active acne.
                        It acts on the hair follicles and the oil glands.
                        the skins pigments absorb the energy and converts it into heat, eliminating pigmentation.
                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li><i>Please inform the doctor about any active pr prior skin conditions.</i></li>
                    </ul>,
                pro:
                    <p>
                        An IPL laser is used to heat your skin for a few minutes.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: " PRP for Face",
                short: "PRP",
                treatment: "Pigmentation",
                sessions: "4-6 (Once in 4-6 weeks)",
                top: "50-60 minutes",
                description:
                    <p>
                        PRP also known as the Vampire Facial, regularly done by international celebs,  is one of the safest skin treatments as it uses the patients own blood to reduce pigmentation and also as a skin booster to maintain the vitality of skin.
                    </p>,

                pre:
                    <ul>
                        <li>Numbing cream is applied for 30 minutes</li>
                        <li>You may require a few blood tests as advised by the doctor.</li>
                        <br></br>
                        <i>Please inform your doctor about any medications you use like blood thinners, anti-epileptics etc.</i>
                    </ul>
                ,
                pro:
                    <p>
                        Blood is drawn and centrifuged.<br></br>
                        Platelets are separated and extracted into thin syringes, and injected into the effected areas.<br></br>
                        Numbing cream helps in making this procedure almost painless.<br></br>
                    </p>,
                post:
                    <ul>
                        <li>You should not apply any serums/oils etc</li>
                        <li>You should not lift weights/excercise/workout for 48 hours.</li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor.</i>
                    </ul>
            },
            {
                name: "Microneedling & MNRF",
                short: "Microneedling & MNRF",
                treatment: "Pigmentation",
                sessions: "4-8 (Once per Month)",
                top: "20 minutes",
                description:
                    <p>
                        Microneedling is a non laser manual procedure, MNRF is a laser procedure.
                        <br></br>
                        Both utilize mutiple micro needles that pierce the skin and help deliver the treatment medicine to the deeper layers of the skin.
                    </p>,

                pre:
                    <ul>
                        <li>Numbing cream is applied for 30 minutes</li>
                        <i>Please inform your doctor about any pre existing conditions like Vitiligo, Lichen planus, etc</i>

                    </ul>,
                pro:
                    <p>
                        Radiofrequency is delivered to the skin through the multiple micro needles by making tiny punctrures into the skin.

                    </p>,

                post:
                    <ul>
                        <li>A short course of oral and topical medication will be given for a period of 7-10 days which has to be strictly followed to protect your skin from any side effects. you can resume your regular prescription after this.</li>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Carbon Laser Toning (Hollywood Laser)",
                short: "Carbon Laser Toning",
                treatment: "Pigmentation",
                sessions: "4-8 (Once per Month)",
                top: "30-40 minutes",
                description:
                    <p>
                        The carbon laser stimulates the collagen production in the skin, which helps in getting plumper and firm skin with a glow.
                        It removes build up of oil and dirt and can help in reducing pigment cells in the skin.
                        <br></br>
                        <br></br>
                        It has no down time. It is also called a lunch break laser as you can resume your regular actrivities immediately afterwards.
                    </p>,

                pre:
                    <ul>
                        <li><i>Please inform your dermatologist about any cosmetic procedures done in the last 2 months, especially dermal filler / toxin (botox) / skin boosters.
                        </i></li>
                        <li>Cleansing</li>
                        <li>Degreasing</li>
                        <li>Application of Carbon Peel</li>
                    </ul>,
                pro:
                    <p>
                        A non oblative laser is used on the carbon peel to painlessly exfoliate and rejuvenate the skin.
                    </p>,

                post:
                    <ul>
                        <li>wait for 2 weeks before getting a dermal filler or toxin treatment</li>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },

            // Acne Scars / Burn Scars
            {
                name: "ILS Injections",
                short: "ILS Injections",
                treatment: "Acne Scars / Burn Scars",
                sessions: "Depending on the scar",
                top: "10 minutes",
                description:
                    <p>
                        Intra Lesional Steroid Injection help is decreasing the scar tissue only for keloidal scars.
                    </p>,

                pre:
                    <ul>
                        <li>Use of silicone sheets are recommended</li>
                    </ul>,
                pro:
                    <p>
                        According to the site and depth of the scar tissue, a calculated safe dose is injected into keloidal type of acne scars.
                    </p>,

                post:
                    <ul>
                        <li>resume regular presciption as advised</li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "TCA CROSS",
                short: "TCA Cross",
                treatment: "Acne Scars / Burn Scars",
                sessions: "This procedure is combined with other procedures",
                top: "Depending on scar",
                description:
                    <p>
                        TCA Cross is Chemical Recontruction Of Skin Scars (CROSS) using Tri Chloroacetic Acid (TCA).
                        <br></br>
                        It is used for atrophic or depressed scars. The strength of TCA ussed depended on the skin type. It is mostly combined with other procedures.
                        When done by expert dermatologists, it is one of the safest and cheapest procedures for acne scar treatment.
                        <br></br>

                    </p>,

                pre:
                    <ul>
                        <li>Strict use of sunscreen prior to visiting the clinic</li>
                    </ul>,
                pro:
                    <p>
                        Each individual scar is treated with TCA, in a series of sessions to ensure that the scar is elevated.
                    </p>,

                post:
                    <ul>
                        <li>Will require a down time of 5-10 days</li>

                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Microneedling & MNRF",
                short: "Microneedling & MNRF",
                treatment: "Acne Scars / Burn Scars",
                sessions: "4-8 (Once per Month)",
                top: "20 minutes",
                description:
                    <p>
                        Microneedling is a non laser manual procedure, MNRF is a laser procedure.
                        <br></br>
                        Both utilize mutiple micro needles that pierce the skin and help deliver the treatment medicine to the deeper layers of the skin.
                    </p>,

                pre:
                    <ul>
                        <li>Numbing cream is applied for 30 minutes</li>
                        <br></br>
                        <i>Please inform your doctor about any pre existing conditions like Vitiligo, Lichen planus, etc</i>

                    </ul>,
                pro:
                    <p>
                        Radiofrequency is delivered to the skin through the multiple micro needles by making tiny punctrures into the skin.

                    </p>,

                post:
                    <ul>
                        <li>A short course of oral and topical medication will be given for a period of 7-10 days which has to be strictly followed to protect your skin from any side effects. you can resume your regular prescription after this.</li>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Fractional CO2 Laser",
                short: "Fractional CO2 Laser",
                treatment: "Acne Scars / Burn Scars",
                sessions: "6-10 (Once in 4-6 weeks)",
                top: "20-25 minutes",
                description:
                    <p>
                        Fractional CO2 is a minimally invasive non surgical laser with a down time of 5-10 days.
                        It is ussed to treat scars and is very effective.
                        It can be conbined with other treatment modalities.
                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not use any medication 2 days prior to the procedure</li>
                        <li><i>If you are on any medication or have an active infection <b>ESPECIALLY connective tissue disorders like Lupus (SLE), Rheumatoid Arthiritis, Scleroderma, etc</b>, please inform the doctor.</i></li>

                    </ul>,
                pro:
                    <p>
                        A Laser beam is projected on the target areas. It tricks the skin into healing itself.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>

                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "PRP",
                short: "PRP",
                treatment: "Acne Scars / Burn Scars",
                sessions: "4-10 (Once per Month)",
                top: "50-60 minutes",
                description:
                    <p>
                        PRP also known as the Vampire Facial, regularly done by international celebs,  is one of the safest skin treatments as it uses the patients own blood.
                        When clubbed with MNRF or fractional CO2, it provides excellent results for treating acne scars and burn scars.
                    </p>,

                pre:
                    <ul>
                        <li>Numbing cream is applied for 30 minutes</li>
                        <li>You may require a few blood tests as advised by the doctor.</li>
                        <br></br>
                        <i>Please inform your doctor about any medications you use like blood thinners, anti-epileptics etc.</i>
                    </ul>
                ,
                pro:
                    <p>
                        Blood is drawn and centrifuged.<br></br>
                        Platelets are separated and extracted into thin syringes, and injected into the infected areas.<br></br>
                        Numbing cream helps in making this procedure almost painless.<br></br>
                    </p>,
                post:
                    <ul>
                        <li>You should not apply any serums/oils etc</li>
                        <li>You should not lift weights/excercise/workout for 48 hours.</li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor.</i>
                    </ul>
            },
            {
                name: "Dermal Fillers",
                short: "IPL",
                treatment: "Acne Scars / Burn Scars",
                sessions: "1, They last for 12-18 months",
                top: "2-30 minutes (Depending on the number of scars)",
                description:
                    <p>
                        Dermal Fillers are made up of Hyaluronic Acid. It is a semi permenant solution and a "quick fix" with minimal down time.
                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                    </ul>,
                pro:
                    <p>
                        Dermal Fillers are injected beneath the scar to elevate it.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li><b>Do NOT get any laser treatments done for the next 2 weeks</b> </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },

            // check sessions and TOD from here///////////////////////////////////


            // Stretch Marks Reduction
            {
                name: "Fractional CO2 Laser",
                short: "Fractional CO2 Laser",
                treatment: "Stretch Marks Reduction",
                sessions: "6-10 (Once in 4-6 weeks)",
                top: "20-25 minutes",
                description:
                    <p>
                        Fractional CO2 is a minimally invasive non surgical laser with a down time of 5-10 days.
                        It is ussed to treat stretch marks and scars, and is very effective.
                        It can be conbined with other treatment modalities.
                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not use any medication 2 days prior to the procedure</li>
                        <li><i>If you are on any medication or have an active infection <b>ESPECIALLY connective tissue disorders like Lupus (SLE), Rheumatoid Arthiritis, Scleroderma, etc</b>, please inform the doctor.</i></li>

                    </ul>,
                pro:
                    <p>
                        A Laser beam is projected on the target areas. It tricks the skin into healing itself.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>

                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Microneedling & MNRF",
                short: "Microneedling & MNRF",
                treatment: "Stretch Marks Reduction",
                sessions: "4-8 (Once per Month)",
                top: "20 minutes",
                description:
                    <p>
                        Microneedling is a non laser manual procedure, MNRF is a laser procedure.
                        <br></br>
                        Both utilize mutiple micro needles that pierce the skin and help deliver the treatment medicine to the deeper layers of the skin.
                    </p>,

                pre:
                    <ul>
                        <li>Numbing cream is applied for 30 minutes</li>
                        <br></br>
                        <i>Please inform your doctor about any pre existing conditions like Vitiligo, Lichen planus, etc</i>

                    </ul>,
                pro:
                    <p>
                        Radiofrequency is delivered to the skin through the multiple micro needles by making tiny punctrures into the skin.

                    </p>,

                post:
                    <ul>
                        <li>A short course of oral and topical medication will be given for a period of 7-10 days which has to be strictly followed to protect your skin from any side effects. you can resume your regular prescription after this.</li>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "PRP",
                short: "PRP",
                treatment: "Stretch Marks Reduction",
                sessions: "4-10 (Once per Month)",
                top: "50-60 minutes",
                description:
                    <p>
                        PRP also known as the Vampire Facial, regularly done by international celebs,  is one of the safest skin treatments as it uses the patients own blood.
                        When clubbed with MNRF or fractional CO2, it provides excellent results for treating stretch marks.
                    </p>,

                pre:
                    <ul>
                        <li>Numbing cream is applied for 30 minutes</li>
                        <li>You may require a few blood tests as advised by the doctor.</li>
                        <br></br>
                        <i>Please inform your doctor about any medications you use like blood thinners, anti-epileptics etc.</i>
                    </ul>
                ,
                pro:
                    <p>
                        Blood is drawn and centrifuged.<br></br>
                        Platelets are separated and extracted into thin syringes, and injected into the effected areas.<br></br>
                        Numbing cream helps in making this procedure almost painless.<br></br>
                    </p>,
                post:
                    <ul>
                        <li>You should not apply any serums/oils etc</li>
                        <li>You should not lift weights/excercise/workout for 48 hours.</li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor.</i>
                    </ul>
            },

            // Anti Aging / Skin Rejuvenation
            {

                name: "Chemical Peels",
                short: "Chemical Peels",
                treatment: "Anti Aging / Skin Rejuvenation",
                sessions: "6-10 (Once per Month)",
                top: "15 minutes",
                description:
                    <p>
                        There are 32+ types of chemical peels used for a range of issues like acne, pigmentation, melasma, anti aging, rejuvenation, skin glow, etc.

                    </p>,

                pre:
                    <ul>
                        <li>Cleansing</li>
                        <li>Degreasing</li>
                        <li>Do not use any active ingredients 1 day prior to procedure</li>
                        <li><i>If you are on any medication or have an active infection, please inform the doctor</i></li>
                    </ul>,
                pro:
                    <p>
                        Its an In-Clinic procedure, where a medication if applied on the target area for a duration of 10-15 minutes based on the type of peel and skin concern.

                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <li>Avoid active ingredients for 3 days</li>
                        <li>Do not apply make up or homecare remedies while on this treatment</li>
                        <li>Do not apply bleach for a few days</li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "MNRF",
                short: "MNRF",
                treatment: "Anti Aging / Skin Rejuvenation",
                sessions: "4-8 (Once per Month)",
                top: "20 minutes",
                description:
                    <p>
                        MNRF is a laser procedure. it utilize mutiple micro needles that pierce the skin and help deliver the treatment medicine to the deeper layers of the skin.
                    </p>,

                pre:
                    <ul>
                        <li>Numbing cream is applied for 30 minutes</li>
                        <br></br>
                        <i>Please inform your doctor about any pre existing conditions like Vitiligo, Lichen planus, etc</i>

                    </ul>,
                pro:
                    <p>
                        Radiofrequency is delivered to the skin through the multiple micro needles by making tiny punctrures into the skin.

                    </p>,

                post:
                    <ul>
                        <li>A short course of oral and topical medication will be given for a period of 7-10 days which has to be strictly followed to protect your skin from any side effects. you can resume your regular prescription after this.</li>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Carbon Laser Toning (Hollywood Laser)",
                short: "Carbon Laser Toning",
                treatment: "Anti Aging / Skin Rejuvenation",
                sessions: "4-8 (Once per Month)",
                top: "30-40 minutes",
                description:
                    <p>
                        The carbon laser stimulates the collagen production in the skin, which helps in getting plumper and firm skin with a glow.
                        It removes build up of oil and dirt and can help in reducing pigment cells in the skin.
                        <br></br>
                        <br></br>
                        It has no down time. It is also called a lunch break laser as you can resume your regular actrivities immediately afterwards.
                    </p>,

                pre:
                    <ul>
                        <li><i>Please inform your dermatologist about any cosmetic procedures done in the last 2 months, especially dermal filler / toxin (botox) / skin boosters.
                        </i></li>
                        <li>Cleansing</li>
                        <li>Degreasing</li>
                        <li>Application of Carbon Peel</li>
                    </ul>,
                pro:
                    <p>
                        A non oblative laser is used on the carbon peel to painlessly exfoliate and rejuvenate the skin.
                    </p>,

                post:
                    <ul>
                        <li>wait for 2 weeks before getting a dermal filler or toxin treatment</li>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: " PRP for Face",
                short: "PRP",
                treatment: "Anti Aging / Skin Rejuvenation",
                sessions: "4-6 (Once in 4-6 weeks)",
                top: "50-60 minutes",
                description:
                    <p>
                        PRP also known as the Vampire Facial, regularly done by international celebs,  is one of the safest skin treatments as it uses the patients own blood to reduce pigmentation and also as a skin booster to maintain the vitality of skin.
                    </p>,

                pre:
                    <ul>
                        <li>Numbing cream is applied for 30 minutes</li>
                        <li>You may require a few blood tests as advised by the doctor.</li>
                        <br></br>
                        <i>Please inform your doctor about any medications you use like blood thinners, anti-epileptics etc.</i>
                    </ul>
                ,
                pro:
                    <p>
                        Blood is drawn and centrifuged.<br></br>
                        Platelets are separated and extracted into thin syringes, and injected into the effected areas.<br></br>
                        Numbing cream helps in making this procedure almost painless.<br></br>
                    </p>,
                post:
                    <ul>
                        <li>You should not apply any serums/oils etc</li>
                        <li>You should not lift weights/excercise/workout for 48 hours.</li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor.</i>
                    </ul>
            },
            {
                name: "Fractional CO2 Laser",
                short: "Fractional CO2 Laser",
                treatment: "Anti Aging / Skin Rejuvenation",
                sessions: "6-10 (Once in 4-6 weeks)",
                top: "20-25 minutes",
                description:
                    <p>
                        Fractional CO2 is a minimally invasive non surgical laser with a down time of 5-10 days.
                        It is ussed to treat stretch marks and scars, and is very effective.
                        It can be conbined with other treatment modalities.
                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not use any medication 2 days prior to the procedure</li>
                        <li><i>If you are on any medication or have an active infection <b>ESPECIALLY connective tissue disorders like Lupus (SLE), Rheumatoid Arthiritis, Scleroderma, etc</b>, please inform the doctor.</i></li>

                    </ul>,
                pro:
                    <p>
                        A Laser beam is projected on the target areas. It tricks the skin into healing itself.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>

                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            
            ///////////// do from here 25 left / 18 unique
            // Botulinum Toxins / HIFU / Skin Boosters / Profilho / Hydra Facials / Extripation
            // Oxygeneo Facial / Basic Medi-Facial / Vitamin C Medi-Facial / MIRA Peels
            // B Glow / Skin Polishing & Micro Dermabrasions / Cryo Therapy / NB UVB phototherapy
            // Anti-Oxident Injections / Electro Cautery / Laser Excesion / Surgical Excession
            {
                name: "Botulinum Toxins",
                // short: "IPL",
                treatment: "Anti Aging / Skin Rejuvenation",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "HIFU",
                // short: "IPL",
                treatment: "Anti Aging / Skin Rejuvenation",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Skin Boosters",
                // short: "IPL",
                treatment: "Anti Aging / Skin Rejuvenation",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Profhilo",
                // short: "IPL",
                treatment: "Anti Aging / Skin Rejuvenation",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },

            // Healthy Skin Care

            {
                name: "IPL Laser",
                short: "IPL",
                treatment: "Healthy Skin Care",
                sessions: "4-6 (Once per Month)",
                top: "20-30 minutes",
                description:
                    <p>
                        Intense Pulsed Light Laser is used to rejuvenate the skin and give it a fair glow.
                        It acts on the hair follicles and the oil glands.
                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li><i>Please inform the doctor about any active pr prior skin conditions.</i></li>
                    </ul>,
                pro:
                    <p>
                        An IPL laser is used to heat your skin for a few minutes.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "PRP",
                short: "PRP",
                treatment: "Healthy Skin Care",
                sessions: "4-10 (Once per Month)",
                top: "50-60 minutes",
                description:
                    <p>
                        PRP also known as the Vampire Facial, regularly done by international celebs,  is one of the safest skin treatments as it uses the patients own blood.
                        When clubbed with MNRF or fractional CO2, it provides excellent results and keeps the skin healthy.
                    </p>,

                pre:
                    <ul>
                        <li>Numbing cream is applied for 30 minutes</li>
                        <li>You may require a few blood tests as advised by the doctor.</li>
                        <br></br>
                        <i>Please inform your doctor about any medications you use like blood thinners, anti-epileptics etc.</i>
                    </ul>
                ,
                pro:
                    <p>
                        Blood is drawn and centrifuged.<br></br>
                        Platelets are separated and extracted into thin syringes, and injected into the effected areas.<br></br>
                        Numbing cream helps in making this procedure almost painless.<br></br>
                    </p>,
                post:
                    <ul>
                        <li>You should not apply any serums/oils etc</li>
                        <li>You should not lift weights/excercise/workout for 48 hours.</li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor.</i>
                    </ul>
            },

            {
                name: "Laser Toning",
                // short: "IPL",
                treatment: "Healthy Skin Care",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Chemical Peels for Glow",
                // short: "IPL",
                treatment: "Healthy Skin Care",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Hydra Facials",
                // short: "IPL",
                treatment: "Healthy Skin Care",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Oxygeneo Facial",
                // short: "IPL",
                treatment: "Healthy Skin Care",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Basic Medi-Facial",
                // short: "IPL",
                treatment: "Healthy Skin Care",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Vitamin C Medi-Facial",
                // short: "IPL",
                treatment: "Healthy Skin Care",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "MIRA Peels",
                // short: "IPL",
                treatment: "Healthy Skin Care",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "B Glow",
                // short: "IPL",
                treatment: "Healthy Skin Care",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Skin Polishing & Micro Dermabrasions",
                // short: "IPL",
                treatment: "Healthy Skin Care",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },          
            {
                name: "Skin Boosters",
                // short: "IPL",
                treatment: "Healthy Skin Care",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Anti-Oxident Injections for Skin Glow",
                // short: "IPL",
                treatment: "Healthy Skin Care",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },

            // Wart / Skin Tags
            {
                name: "Electro Cautery",
                // short: "IPL",
                treatment: "Wart / Skin Tags",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Laser Excesion",
                // short: "IPL",
                treatment: "Wart / Skin Tags",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Cryo Therapy",
                // short: "IPL",
                treatment: "Wart / Skin Tags",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },


            //  Psorassis 
            {
                name: "NB UVB phototherapy",
                // short: "NB-UVB",
                treatment: "Psoriasis",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },

            // Vitiligo
            {
                name: "NB UVB phototherapy",
                // short: "NB-UVB",
                treatment: "Vitiligo",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },

            // Milia
            {
                name: "Electrocautery",
                // short: "IPL",
                treatment: "Wart / Skin Tags",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },

            // Syringomas / Xanthelesmas
            {
                name: "TCA CROSS",
                short: "TCA Cross",
                treatment: "Syringomas / Xanthelesmas",
                sessions: "This procedure is combined with other procedures",
                top: "Depending on scar",
                description:
                    <p>
                        TCA Cross is Chemical Recontruction Of Skin Scars (CROSS) using Tri Chloroacetic Acid (TCA).
                        <br></br>
                        It is used to treat Syringomas and Xanthelesmas. The strength of TCA ussed depended on the skin type. It is mostly combined with other procedures.
                        When done by expert dermatologists, it is one of the safest and cheapest procedures.
                        <br></br>

                    </p>,

                pre:
                    <ul>
                        <li>Strict use of sunscreen prior to visiting the clinic</li>
                    </ul>,
                pro:
                    <p>
                        Each individual scar is treated with TCA, in a series of sessions to ensure that the scar is elevated.
                    </p>,

                post:
                    <ul>
                        <li>Will require a down time of 5-10 days</li>

                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },

            {
                name: "Electrocautery",
                // short: "IPL",
                treatment: "Wart / Skin Tags",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Surgical Excession",
                // short: "IPL",
                treatment: "Wart / Skin Tags",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },

            // Molluscum Contagiosum 
            {
                name: "Extripation",
                // short: "IPL",
                treatment: "Wart / Skin Tags",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },

            // freckles
            {
                name: "Surgical Excision",
                // short: "IPL",
                treatment: "Wart / Skin Tags",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Laser",
                // short: "IPL",
                treatment: "Wart / Skin Tags",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            }

            ////////////////////// do till here
        ]
    },
    {
        category: "Hair",
        procedures: [
            {
                name: "PRP: Platelet Rich Plasma Therapy",
                short: "PRP",
                treatment: "Hair Loss Treatment",
                sessions: "6-8",
                top: "60 minutes",
                description:
                    <p >
                        This therapy is used for hair regrowth in cases of severe hair loss, female/male patterned hairloss, etc.<br />
                        It is an interventional treatment that helps in regrowing and preserving the hair on the scalp by allowing your body platelets to help regrow your hair naturally.
                    </p>,

                pre:
                    <ul>
                        <li>Hair should be freshly washed</li>
                        <li>Numbing cream is applied for 30 minutes</li>
                        <li>You may require a few blood tests as advised by the doctor.</li>
                        <br></br>
                        <i>Please inform your doctor about any medications you use like blood thinners, anti-epileptics etc.</i>
                    </ul>
                ,
                pro:
                    <p>
                        Blood is drawn and centrifuged.<br></br>
                        Platelets are separated and extracted into thin syringes, and injected into the scalp.<br></br>
                        Numbing cream helps in making this procedure almost painless.<br></br>
                    </p>,
                post:
                    <ul>
                        <li>You should not wash the scalp for a day. </li>
                        <li>You should not apply any serums/oils etc</li>
                        <li>You should not lift weights/excercise/workout for 48 hours.</li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor.</i>
                    </ul>
            },
            {
                name: "Mesotherapy",
                short: "Mesotherapy",
                treatment: "Hair Loss Treatment",
                sessions: "4-8",
                top: "20-40 minutes",
                description:
                    <p>
                        Mesotherapy is a procedure that allows formation of micro channels and meso solutions are applied with it for hair regrowth.
                        it's a non-invasive, no surgical, painless treatment that delivers growth factors into the hair folicles of the scalp, thereby promoting new hair growth
                        and strengthens existing hair.
                    </p>,

                pre:
                    <ul>
                        <li>Hair should be freshly washed</li>
                        <li>Numbing cream is applied for 30 minutes</li>
                    </ul>,
                pro:
                    <p>
                        A meso device is used to deliver hair growth factor serums into the scalp.
                    </p>,

                post:
                    <ul>
                        <li>You should not wash the scalp for a day. </li>
                        <li>You should not apply any serums/oils etc</li>
                        <li>You should not lift weights/excercise/workout for 48 hours.</li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor.</i>
                    </ul>
            },
            {
                name: "LLLLT: Low Level Laser Light Treatment",
                short: "LLLLT",
                treatment: "Hair Loss Treatment",
                sessions: "Twice per Week",
                top: "TBA",
                description:
                    <p>
                        Low-Level Light Laser Therapy uses a unique, light-based photobiomodulation technology that acts as an adjuvant to other treatment modalities.
                        It helps in strengthening hair shafts, and requires multiple sessions.
                        <br></br> <br></br>
                        <i>Discuss with your doctor for the best options for you.</i>
                    </p>,

                pre:
                    <ul>
                        <li>None</li>
                    </ul>,
                pro:
                    <p>
                        An LED light is placed on the scalp, which helps to stimulate hair growth.
                    </p>,

                post:
                    <ul>
                        <li>None</li>
                    </ul>
            },
            {
                name: "ILS: Intra Lesional Steroid Injections",
                short: "ILS",
                treatment: "Hair Loss Treatment",
                sessions: "TBA",
                top: "TBA",
                description:
                    <p>
                        Intra Lesional Steriod Injections are used for alopecia areata.
                        It is a safe, simple procedure and in the right hands and has minimal to no side effects.
                        It is usually combined with oral and topical medications as per the extent of the alopecia.
                    </p>,

                pre:
                    <ul>
                        <li>Diagnosis by dermascopy</li>
                        <li>Blood tests as per the doctors advice</li>
                    </ul>,
                pro:
                    <p>
                        low dose mild steroid injections are given in the patch of hair loss
                        to arrest loss of hair follicles and promote new hair growth.
                    </p>,

                post:
                    <ul>
                        <li>None</li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor.</i>
                    </ul>
            },
            {
                name: "DLHR: Diode Laser Hair Reduction",
                short: "DLHR",
                treatment: "Laser Hair Reduction",
                sessions: "6-10 (Once per Month)",
                top: "TBA",
                description:
                    <p>
                        Laser hair reduction is always misnamed as removal.
                        Diode Laser hair reduction destroys the hair bulb and results in long term/permanent hair reduction.

                        <br></br>
                        <b>We use a Triple-Wavelength Diode Laser (FDA Approved) in our clinic</b>
                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                        <br></br>
                        <i>In case of doubt of hair growth secondary to PCOS, certain tests may be evaluated prior to starting your LHR sessions.</i>
                    </ul>,
                pro:
                    <p>
                        Therapist double checks the area to be lasered and cooling is done prior to procedure.
                        Laser procedure itself is painless and has a cold tip that prevents burns.

                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
            {
                name: "Electrolysis",
                short: "Electrolysis",
                treatment: "Laser Hair Reduction",
                sessions: "8-12 (Once in a Month)",
                top: "40 minutes",
                description:
                    <p>
                        Electrolysis work on black, grey and white hair. in comparision to diode laser.
                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>

                    </ul>,
                pro:
                    <p>
                        The precedure involves the destruction of individual hair follicles, therefore it is time consuming. <br></br><b><i>please discuss options with your dermatologist</i></b>.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>

                    </ul>
            },
            {
                name: "IPLL: Intense Pulsed Light Laser",
                short: "IPLL",
                treatment: "Laser Hair Reduction",
                sessions: "10-12 (Once per Month)",
                top: "Depending on area involved",
                description:
                    <p>
                        it is a painless laser that work on thin and light colered hair a well.
                        However IPL requires more number of sessions compared to diode laser. it is best for hair left over after completing diode LHR sessions.

                    </p>,

                pre:
                    <ul>
                        <li>Apply sunscreen and moisturizer</li>
                        <li>Do not tweez/pluck/thread/wax hair 15 days prior to procedure</li>
                    </ul>,
                pro:
                    <p>
                        It uses intense pulsed light to heat the hari follicles and prevent regrowth.
                    </p>,

                post:
                    <ul>
                        <li>Apply sunscreen and moisturizer religiously </li>
                        <li>Do not use fragrances or soap for few days </li>
                        <br></br>
                        <i>Follow the post procedure instructions as advised by the doctor. In case of post procedure reactions, contact the therapist/clinic immediately.</i>
                    </ul>
            },
        ]
    }

]