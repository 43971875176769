import React from 'react'
import { Link } from 'react-router-dom';

BookNow.defaultProps = {
    category: '',
    treatment: '',
    procedure: '',
    className: 'btn btn-primary p-2 mt-1'
}

export default function BookNow (props) {
    const type = props.type
    return (
        type === "Appointment" ?
            <Link to="/appointment"
                className={props.className}
                state={{
                    category: props.category,
                    treatment: props.treatment,
                    procedure: props.procedure
                }}
            >
                Appointment
            </Link>
            :
            <Link
                to="/appointment"
                className={props.className}
                state={{
                    category: props.category,
                    treatment: props.treatment,
                    procedure: props.procedure
                }}
            >
                Book Now
            </Link>


    )
}
