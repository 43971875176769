import React from 'react'
import Legend from '../components/Legend'
import Navbar from '../components/Navbar'
import Spinner from '../components/Spinner'
import Footer from '../components/Footer';
import { procedureData } from '../data/procedureData';
import BookNow from '../components/BookNow';

export default function ProcedureItem() {
    var url = window.location.href;
    var myregex = /procedures\/(.*)/
    var match = myregex.exec(url)
    var name = match[1].replaceAll("-", " ")

    var item = procedureData.map((cat) => {
        for (var i = 0; i < cat.procedures.length; i++) {
            if (cat.procedures[i].name === name) {
                var x = cat.procedures[i]
                x.category = cat["category"]
                return x
            }
        }
        return null
    }).filter((item) => { return item != null })[0]


    var category = item.category
    var short = item.short
    var treatment = item.treatment
    var description = item.description
    var session = item.sessions
    var pre = item.pre
    var pro = item.pro
    var top = item.top
    var post = item.post
    return (
        <>
            <Spinner />
            <Navbar />
            <Legend pageName1="Procedures" pageName2={"Procedure: " + short} />

            {/* Procedure Item Start */}
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container mb-5">

                    {/* Name */}
                    <div className="section-title mb-5">
                        <h5 className="position-relative d-inline-block text-primary text-uppercase">Procedure</h5>
                        <h1 className="display-5 mb-0">{name}</h1>
                    </div>

                    {/* Info */}
                    <div className="d-flex align-items-start flex-column mb-5">
                        <p className="mb-auto p-2"><b>Type:</b> {treatment}</p>
                        <p className="mb-auto p-2"><b>Sessions:</b> {session}</p>
                        <p className="mb-auto p-2"><b>Duration:</b> {top}</p>
                    </div>

                    {/* Description */}
                    <div className="text-start p-2">
                        <p><b>Description:</b></p>
                        {description}
                        <br></br>
                        <p><b>Pre-Procedure:</b></p>
                        {pre}
                        <br></br>
                        <p><b>Procedure:</b></p>
                        {pro}
                        <br></br>
                        <p><b>Post-Procedure:</b></p>
                        {post}

                        {/* <Player>
                            <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
                        </Player> */}
                    </div>

                    <BookNow className='btn btn-primary p-2 mt-1' category={category} treatment={treatment} procedure={name} />

                </div>
            </div>
            {/* Procedure Item End */}

            <Footer />
        </>
    )
}
