import React, { useState } from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Spinner from '../components/Spinner'
import Legend from '../components/Legend'
import { TextField } from '@mui/material'
import ProcedureItemCard from '../components/ProcedureItemCard'
import { procedureData } from '../data/procedureData'

export default function Procedures () {

    var items = procedureData.map((cat) => {
        var list = []
        for (var i = 0; i < cat.procedures.length; i++) {
            var li = {
                name: cat.procedures[i].name,
                short: cat.procedures[i].short,
                treatment: cat.procedures[i].treatment,
                category: cat.category
            }
            list.push(li)
        }

        return list
    })
    items = items[0].concat(items[1])

    const [searchInput, setSearchInput] = useState('')

    const handleSearchInput = (e) => {
        var lowerCase = e.target.value.toLowerCase();
        setSearchInput(lowerCase)
    }

    return (
        <>
            <Spinner />
            <Navbar />
            <Legend pageName1="Our Procedures" pageName2="Procedures" />

            {/* <!-- Procedures Start --> */}
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row g-5 mb-5">
                        <div className="col-lg-12">
                            <div className="section-title mb-5">
                                <h5 className="position-relative d-inline-block text-primary text-uppercase">Our Procedures</h5>
                                <h1 className="display-5 mb-0">We Offer Safe and Hassle-Free Procedures</h1>
                            </div>

                            <div className="row mb-5">
                                <TextField
                                    id="outlined-basic"
                                    onChange={handleSearchInput}
                                    variant="outlined"
                                    fullWidth
                                    label="Search"
                                />
                            </div>
                            <div className="row g-3 justify-center">
                                {

                                    items.filter((item) => {
                                        if (searchInput === '') {
                                            return item;
                                        } else if (item.name) {
                                            var lowerCase = item.name.toLowerCase()
                                            if (lowerCase.includes(searchInput)) {
                                                return item;
                                            }
                                        }

                                    }).map((item, index) => {
                                        return <ProcedureItemCard item={item} key={index} />
                                    })
                                }
                            </div>



                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Procedures End --> */}

            {/* {
                items.map((item, index) => {
                    return <p key={index}>{item.name}</p>
                })
            } */}

            <Footer />
        </>
    )
}
