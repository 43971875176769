export const treatmentData = [
    {
        category: "Skin",
        treatments: [
            {
                name: "Acne / Pimples / Comedones",
                description:
                    <p>
                        Acne can be of various types - on face, back, hormonal, blackheads / whiteheads (comodones), Folliculitis, etc.
                        <br></br>
                        <br></br>
                        Get a Skin Evaluation now to diagnose your problems and get them treated.
                    </p>,
                image: "assets/img/treatments/acne.jpg"

            },
            {
                name: "Milia",
                description:
                    <p>
                        Milia are tiny bumps that are formed anywhere on the body when dead skin cells get caught and trapped under the new skin.
                        <br></br>
                        <br></br>
                        They have to be removed by excesion, laser or electrocautery. Over the counter medication can also be prescribed by a dermatologist.
                        <br></br>
                    </p>,
                image: "assets/img/treatments/milia.jpg"

            },
            {
                name: "Pigmentation",
                description:
                    <p>
                        Pigmentation includes Acne marks/Pigmentation spots due to acne, Freckles, Melasma, PDL(pigmentary Demarcation Line), etc.
                        <br></br> <br></br>
                        Get a Dermascopic Examination in-clinic now to diagnose your problems and get them treated.
                    </p>,
                image: "assets/img/treatments/pigmentation.jpg"

            },
            {
                name: "Healthy Skin Care",
                description:
                    <p>
                        Just Skin Maintainance. If your skin is having no issues and you are just looking to keep it healthy and well maintained, here are the options for you.

                    </p>,
                image: "assets/img/treatments/healthyskincare.jpg"

            },
            {
                name: "Acne Scars / Burn Scars",
                description:
                    <p>
                        Scars can be either Acne Scars or Post Burn Scars.
                        <br></br><br></br>
                        Acne Scars are of multiple types, they can be above the skin (hypertrophic or keloidal scars)
                        or below the skin (pitted, rolling, atrophic or box scars).
                        <br></br><br></br>
                        Post Burn Scars are old scars due to burns, scalds, etc.
                        <br></br><br></br>
                        Ussually a combnination of 2 therapies are required for optimal results.
                        <br></br> <br></br>
                        Get a Skin Evaluation now to discuss your options with the doctor.
                    </p>,
                image: "assets/img/treatments/acnescars.jpg"
            },
            {
                name: "Anti Aging / Skin Rejuvenation",
                description:
                    <p>
                        Looking for reducing your wrinkles and fine lines?
                        <br></br>
                        You are in the right place!
                        <br></br><br></br>
                        A combination of treatments are required, get a Skin Analysis and discuss with the doctor.
                        <br></br> <br></br>
                    </p>,
                image: "assets/img/treatments/skinrejuvenation.jpg"

            },
            {
                name: "Warts / Skin Tags",
                description:
                    <p>
                        Warts are pockets of viral infection that protrude from the skin. They require excision by ElectroCautery / radio frequency and cryotherapy along with medical management. The infection can spread from one area to another, and from person to person.
                        <br></br> <br></br>
                        Skin tags are benign, harmless lesions which are protusions of skin. They are not infectious and do not spread but can increase in size and number, therefore they are removed for cosmetic reasons.
                        <br></br> <br></br>

                    </p>,
                image: "assets/img/treatments/wart.jpg"

            },
            {
                name: "Syringomas / Xanthelesmas",
                description:
                    <p>
                        Syringomas are bumps that occur due to over active sweat glands. Xanthalesmas are cholestrol deposits.
                        <br></br> <br></br>
                        Both are commonly formed around the eyes. They are not self-resolving and require treatment for cosmetic appearance. They can be treated with laser, electrocautery or TCA treatment.
                        <br></br> <br></br>

                    </p>,
                image: "assets/img/treatments/xanthalama.jpg"

            },
            {
                name: "Skin Infections",
                description:
                    <p>
                        Skin Infections can be:
                        <br></br> <br></br>
                        <b>Fungal:</b> Tinea, Candidiasis, Oral Thrush, Athletes Foot, Jocks Itch, etc.
                        <br></br> <br></br>
                        <b>Viral:</b> Tomato Flu, Chicken Pox, Measles, Zoster, Herpes, Monkey pox, SimpleX, HFMD, etc.
                        <br></br> <br></br>
                        <b>Bacterial:</b> impetigo, skin TB, erysipelas, celulitus,  erythrasma, etc.
                        <br></br> <br></br>
                        <b>Parasitic:</b> Lice, Scabbies, etc.

                    </p>,
                image: "assets/img/treatments/skininfection.jpg"

            },
            {
                name: "Molluscum Contagiosum",
                description:
                    <p>
                        Mollluscum Contagiosum is a viral skin infection that results in small, painless, shiny bumps which can be itchy.
                        They can spread from person to person, and from one area to another.
                        <br></br> <br></br>
                        They are commonly found in school going children and their family members.
                        it can also be transmitted as an STD in a few cases.
                        Treating them as soon as possible is a priority to prevent spread of infection.

                        <br></br> <br></br>

                    </p>,
                image: "assets/img/treatments/molluscum.jpg"

            },
            {
                name: "Psoriasis",
                description:
                    <p>
                        Psoriasis can be present on any part of the body in any age group of patients. It is not contagious but can be present in more than one member of the family.
                        <br></br> <br></br>
                        it requires extensive medical management for a long duration of time. it is of 2 types, stable and unstable.
                        Other than medical management, certain procedures like phototherapy and rituximab infusions may be required.
                    </p>,
                image: "assets/img/treatments/psoriasis.jpg"

            },
            {
                name: "Vitiligo",
                description:
                    <p>
                        Vitligo is an auto-immune condition where there is a loss of pigment in the skin.
                        <br></br>
                        <br></br>
                        It can be in patches or all over the body.
                        It can occur in any age group.
                        Its not contagious.

                        The treatment is difficult but possible, treatment options for large areas of involvment can be with NB:UVB Phototherapy.
                        In small areas of involvement, Vitiligo surgeries can be considered.

                    </p>,
                image: "assets/img/treatments/vitiligo.jpg"

            },
            {
                name: "Stretch Marks Reduction",
                description:
                    <p>
                        Stretch marks ussually occur because of excess weight gain, pregnancy, steriod intake or application, endocrine disturbances, etc.
                        <br></br> <br></br>
                        The diagnosis is fairly simple but the treatment takes time.
                        <br></br> <br></br>
                        As Stretch Mark Reduction is a complicated process, discuss your options with the doctor.
                    </p>,
                image: "assets/img/treatments/stretchmarks.jpg"

            },
            {
                name: "Freckles",
                description:
                    <p>
                        Freckles are small spots on your skin that range in color from red to brown. They're commonly seen on sun-exposed areas, including your face, neck, back, upper chest, hands and arms. Freckles are usually harmless, but like any lesion, should be watched for any changes.
                        <br></br>
                        <br></br>
                        Freckles can be treated by:
                        <br></br>
                        Excision
                        <br></br>
                        Laser
                        <br></br>
                    </p>,
                image: "assets/img/treatments/freckles.jpg"

            },
            {
                name: "Birth Marks / NEVUS / Moles",
                description:
                    <p>
                        Birth marks / NEVUS are marks on the body present since birth and can be treated by:
                        <br></br>
                        Q-Switch Nd-Yag laser

                        <br></br>
                        <br></br>
                        Moles are a common type of skin growth. They often appear as small, dark brown spots and are caused by clusters of pigment-forming cells. They can be treated by:
                        <br></br>
                        Excision by ElectroCautery
                        <br></br>
                        Excision by Laser
                        <br></br>
                        Surgical Excision
                    </p>,
                image: "assets/img/treatments/moles.jpg"

            },
            {
                name: "Eczema / Dermatitis",
                description:
                    <p>
                        Eczema is a term for several different types of skin swelling. it is also called dermatitis. it mostly causes dry and itchy skin, rashes on the face, inside the elbows and behind the knees, and on the hands and feet. Scratching the skin can cause it to turn red, and to swell and itch even more.
                        <br></br> <br></br>
                        It includes:<br></br>
                        Atopic Dermatitis<br></br>
                        Hand Eczema<br></br>
                        Sebhoerhic Dermatitis<br></br>
                        Plantar Eczema <br></br>
                        Contact Dermatitis<br></br>
                        Irritant Dermatitis<br></br>
                        Allergic Dermatitis<br></br>
                        etc...<br></br>

                    </p>,
                image: "assets/img/treatments/eczema.jpg"

            },

        ]
    },
    {
        category: "Hair",
        treatments: [
            {
                name: "Hair Loss Treatment",
                description:
                    <p>
                        Hair loss can occur due to various reasons like male/female patterned hair loss, infections on the scalp, lice, etc.
                        <br></br>
                        <br></br>
                        Get a Dermascopy now to diagnose what the causes are and get them treated.
                    </p>,
                image: "assets/img/treatments/hairloss.jpg"
            },
            {
                name: "Laser Hair Reduction",
                description:
                    <p>
                        Laser hair reduction is the first choice of treatment in case of unwanted hair anywhere on the body.
                        Unwanted hair can be of 2 types:
                        <br></br> <br></br>
                        1. Hair naturally present on the body.
                        For example hands, legs, underarms, etc.
                        <br></br> <br></br>
                        2. Hair arising because of issues like hormonal issues, PCOS, endocrine disturbances and pregnancy.
                        For example on the face, chest, back, etc.
                        <br></br><br></br>
                    </p>,
                image: "assets/img/treatments/laserhairremoval.jpg"

            }
        ]
    },
    // {
    //     category: "Nails",
    //     treatments: [

    //         {
    //             name: "In-Grown Nail Removal",
    //             description:
    //                 <p>
    //                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec porttitor urna quis nisi ornare, et tempus sapien convallis. Mauris ac sagittis orci, ac tincidunt neque. Praesent orci odio, dignissim nec felis non, lobortis cursus tortor.
    //                 </p>,
    //             image: "assets/img/treatments/.jpg"
    //         },
    //         {
    //             name: "Hair Removal",
    //             description:
    //                 <p>
    //                     TBA
    //                 </p>,
    //             image: "assets/img/treatments/.jpg"

    //         }]
    // },
]