export const Styles = {
    Calendar: {
        Color: {
            primary: "rgb(0, 0, 0)",
            primaryFaded: 'rgb(14, 122, 169, 0.25)',
            primaryToday: 'rgb(238, 249, 254, 1)'
        }
    },


}