import React from 'react'
export default function Legend (props) {
    return (
        <>
            <div className="container-fluid bg-primary py-5 hero-header mb-5" style={{ maxHeight: "50vh" }}>
                <div className="row py-3">
                    <div className="col-12 text-center">
                        <h1 className="display-3 text-white animated zoomIn">{props.pageName1}</h1>
                        <div className='d-flex flex-row justify-content-center'>
                            <h4 href="" className="h4 text-white ">Home</h4>
                            <i className="far fa-circle text-white px-2 " style={{ marginTop: "8px" }}></i>
                            <h4 href="" className="h4 text-white ">{props.pageName2}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
