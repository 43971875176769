import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Button } from '@mui/material';

export default function Footer() {
    return (
        <>
            <div className="container-fluid bg-dark text-light py-5 wow fadeInUp" data-wow-delay="0.3s" style={{ marginTop: "-75px" }}>
                <div className="container pt-5">
                    <div className="row g-5 pt-4">
                        <div className="col-lg-6 col-md-6">
                            <h3 className="text-white mb-4">Get In Touch</h3>
                            <p className="mb-2"><i className="bi bi-geo-alt text-primary me-2"></i>Salarjung Colony, Hyderabad, India</p>
                            <p className="mb-2"><i className="bi bi-envelope-open text-primary me-2"></i>dermalulu.clinic@gmail.com</p>
                            <p className="mb-0"><i className="bi bi-telephone text-primary me-2"></i>080089 45533</p>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <h3 className="text-white mb-4">Follow Us</h3>
                            <Button variant="" href="https://www.facebook.com/lulu.derma.clinic/"><FacebookIcon fontSize='large' /></Button>
                            <Button variant="" href="https://www.instagram.com/lulu.derma"><InstagramIcon fontSize='large' /></Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid text-light py-4" style={{ background: "#051225" }}>
                <div className="container">
                    <div className="row g-0">
                        <div className="col-md-6 text-center text-md-start">
                            <p className="mb-md-0">&copy; LuLuDerma. All Rights Reserved.</p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
