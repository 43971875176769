import React, { useState } from 'react'
import { Collapse, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import { procedureData } from '../data/procedureData';
import BookNow from './BookNow';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export default function TreatmentItemCard (props) {


    const name = props.item.name
    const category = props.item.category
    const description = props.item.description
    const picture = props.item.image

    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    var items = procedureData.map((item) => {
        if (item.category === category) {
            var li = [];
            for (var i = 0; i < item.procedures.length; i++) {
                if (item.procedures[i].treatment === name) {
                    li.push({ name: item.procedures[i].name, short: item.procedures[i].short })
                }
            }
        } else {
            li = null
        }
        return li

    })

    var procedures = items.filter((item) => {
        return item != null
    }
    )

    return (
        <>
            <div className="col-lg-12  wow zoomIn" data-wow-delay="0.6s">

                <div className="row align-items-center position-relative bg-light" style={{ padding: "2%" }}>
                    <div className='col-lg-8 col-xs-1 float-start'>
                        <h5 className=''>{name}</h5>

                    </div>
                    <div className='col-lg-2 col-xs-1'>
                        <p className=' mt-3 ' style={{ color: "rgb(28, 144, 189)" }}><b>Category: <br></br></b>{category}</p>
                    </div>

                    <Collapse in={!expanded} className='col-lg-2 align-items-end'>
                        <BookNow className='btn btn-primary p-2 mt-1' category={category} procedure={name} />
                    </Collapse>

                    <Collapse in={!expanded} className='col-lg-12  align-items-center' onClick={handleExpandClick}>
                        <ArrowDropDownIcon fontSize='large' />
                    </Collapse>
                    <Collapse in={expanded} className='col-lg-12 align-items-center' onClick={handleExpandClick}>
                        <ArrowDropUpIcon fontSize='large' />
                    </Collapse>


                    <Collapse in={expanded} >
                        <div className='row mt-3 align-items-center position-relative'>

                            <div className='col-lg-6 mt-2'>
                                <img src={[picture]} alt="" style={{ borderRadius: "50%", maxHeight: "30vh" }} />
                            </div>

                            <div className='col-lg-6 mt-5'>
                                {description}
                            </div>

                        </div>
                        <div className='col-lg-12 mt-2 align-items-center position-relative'>
                            <h6><b>Related Procedures:</b></h6>
                            {/* <br></br> */}

                            {
                                procedures[0].length > 0 ?


                                    procedures[0].map((item, index) => {
                                        var a = JSON.stringify(item.name).replace(/ /g, "-").replace(/"/g, "")
                                        if (!item.short) {
                                            return <div><Link key={index} to={""}>{item.name}</Link></div>
                                        } else {
                                            return <div><Link key={index} to={"/procedures/" + a}>{item.name}</Link></div>

                                        }
                                    })
                                    :
                                    <div><p>As per the condition, the doctor will advice if there is any procedure required</p></div>
                            }

                        </div>

                        <div className='col-lg-12 pt-2'>
                            <Typography>If you need assistance before booking an appointment, please feel free to <Link to="/contact">Contact Us</Link></Typography>
                            <br />
                            <Typography>If you are ready,</Typography>
                            <br />
                            <BookNow className='btn btn-primary p-2 mt-1' category={category} treatment={name} />
                        </div>
                    </Collapse>

                </div>
            </div>


        </>


        // <div onClick={handleExpandClick} className="col-lg-12 wow zoomIn" data-wow-delay="0.6s" style={{ textTransform: 'none' }}>

        //     <div className="row align-items-center position-relative bg-light" style={{ padding: "2%" }}>

        //         <div className='col-lg-8 col-xs-1 float-start'>
        //             <h5 className=''>{name}</h5>
        //         </div>

        //         <div className='col-lg-2 col-xs-1'>
        //             <h6 className=' mt-3 text-black'><b>Category: </b>{category}</h6>
        //         </div>

        // <Collapse in={expanded} >
        //     <div className='row mt-3 align-items-center position-relative'>

        //         <div className='col-lg-6 mt-2'>
        //             <img src={[picture]} alt="" style={{ borderRadius: "50%" }} />
        //         </div>

        //         <div className='col-lg-6 mt-2'>
        //             {description}
        //         </div>

        //     </div>
        //     <div className='col-lg-12 mt-2 align-items-center position-relative'>
        //         <h6><b>Related Procedures:</b></h6>
        //         {/* <br></br> */}

        //         {
        //             procedures[0].map((item, index) => {
        //                 var a = JSON.stringify(item).replace(/ /g, "-").replace(/"/g, "")
        //                 return <div><Link  key={index} to={"/procedures/" + a}>{item}</Link></div>
        //             }
        //             )
        //         }

        //     </div>

        //     <div className='col-lg-12 pt-2'>
        //         <Typography>If you need assistance before booking an appointment, please feel free to <Link to="/contact">Contact Us</Link></Typography>
        //         <br />
        //         <Typography>If you are ready,</Typography>
        //         <br />
        //         <BookNow className='btn btn-primary p-2 mt-1' category={category} treatment={name} />
        //     </div>
        // </Collapse>

        //     </div>
        // </div>

    )
}
