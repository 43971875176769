import React from 'react'

export default function Testemonials() {
    return (
        <>
        
            <div className="container-fluid mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ marginTop: "90px" }}>
                <div className="container py-5 bg-primary">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className=" testimonial-carousel p-5 wow zoomIn" data-wow-delay="0.6s">
                                <div className="testimonial-item text-center text-white">
                                    <img className="img-fluid mx-auto rounded mb-4" src="img/testimonial-1.jpg" alt="" />
                                    <p className="fs-5">Dolores sed duo clita justo dolor et stet lorem kasd dolore lorem ipsum. At lorem lorem magna ut et, nonumy labore diam erat. Erat dolor rebum sit ipsum.</p>
                                    <hr className="mx-auto w-25" />
                                    <h4 className="text-white mb-0">Client Name</h4>
                                </div>
                                <div className="testimonial-item text-center text-white">
                                    <img className="img-fluid mx-auto rounded mb-4" src="img/testimonial-2.jpg" alt="" />
                                    <p className="fs-5">Dolores sed duo clita justo dolor et stet lorem kasd dolore lorem ipsum. At lorem lorem magna ut et, nonumy labore diam erat. Erat dolor rebum sit ipsum.</p>
                                    <hr className="mx-auto w-25" />
                                    <h4 className="text-white mb-0">Client Name</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
