import React, { useRef, useState } from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Spinner from '../components/Spinner'
import Legend from "../components/Legend"
import emailjs from '@emailjs/browser';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert (props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Contact () {


    const form = useRef();

    const [open, setOpen] = useState(false);
    const [submitRes, setSubmitRes] = useState(false)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_CONTACT_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
            .then((result) => {
                e.target.reset()
                setSubmitRes(true)
                setOpen(true)

            }, (error) => {
                setSubmitRes(false)
                setOpen(true)
            });
    };

    return (
        <>
            <Spinner />
            <Navbar />
            <Legend pageName1="Contact Us" pageName2="Contact" />

            {submitRes ?
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        Message Sent! We will get back to you shortly
                    </Alert>
                </Snackbar>
                :
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        Failed! Please Try Again
                    </Alert>
                </Snackbar>}

            {/* <!-- Contact Start --> */}
            <div className="container-fluid py-5">
                <div className="container">
                    <div className="row g-5">

                        <div className="col-lg-12 col-md-12 wow slideInUp mb-5" data-wow-delay="0.3s" style={{ marginBottom: "30px" }}>
                            <form ref={form} onSubmit={sendEmail}>
                                <div className="row g-3">
                                    <div className="col-12" >
                                        <input name="userName" type="text" className="form-control border-0 bg-light px-4" placeholder="Your Name" style={{ height: "55px" }} required />
                                    </div>
                                    <div className="col-12">
                                        <input name="userEmail" type="email" className="form-control border-0 bg-light px-4" placeholder="Your Email" style={{ height: "55px" }} required />
                                    </div>
                                    <div className="col-12">
                                        <input name="userSubject" type="text" className="form-control border-0 bg-light px-4" placeholder="Subject" style={{ height: "55px" }} required />
                                    </div>
                                    <div className="col-12">
                                        <textarea name="message" className="form-control border-0 bg-light px-4 py-3" rows="5" placeholder="Message" required></textarea>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Contact End --> */}

            <Footer />

        </>
    )


}
