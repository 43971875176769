import React from 'react'
import { Link } from 'react-router-dom';
import BookNow from './BookNow';

export default function ProcedureItemCard (props) {

    const name = props.item.name
    const short = props.item.short
    const treatment = props.item.treatment
    const category = props.item.category
    var linkValue = name.replace(/ /g, "-").replace(/"/g, "").replace(/\//g, "")

    // const image = props.item.image


    return (

        short ?

            <Link to={"/procedures/" + linkValue} className="col-lg-12 service-item wow zoomIn" data-wow-delay="0.6s">

                <div className="row align-items-center position-relative bg-light" style={{ padding: "2%" }}>
                    <div className='col-lg-8 col-xs-1 float-start'>
                        <h5 className=''>{name}</h5>

                    </div>
                    <div className='col-lg-2 col-xs-1'>

                        <p className=' mt-3 text-black'><b>Type: <br></br></b>{treatment}</p>

                    </div>
                    <div className='col-lg-2 align-items-end'>
                        <BookNow className='btn btn-primary p-2 mt-1' category={category} treatment={treatment} procedure={name} />
                    </div>
                </div>
            </Link>

            :
            <></>
    )
}
