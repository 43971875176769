import React from 'react';
import { Link } from 'react-router-dom';
import BookNow from './BookNow';

export default function Navbar () {

    return (
        <>

            <nav className="navbar navbar-expand-lg bg-white navbar-light shadow-sm px-5 py-3 py-lg-0" >
                <Link to="/" className="p-0">
                    <img src="assets/img/logo.jpg" alt='' style={{maxWidth: "150px"}}/>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0 ">
                        <Link to="/" className="nav-item nav-link">Home</Link>
                        <Link to="/about" className="nav-item nav-link">About</Link>
                        <Link to="/treatments" className="nav-item nav-link">Treatments</Link>
                        <Link to="/procedures" className="nav-item nav-link">Procedures</Link>
                        <Link to="/contact" className="nav-item nav-link">Contact Us</Link>
                        <div className="nav-item nav-link"></div>
                    </div>
                    <BookNow className='btn btn-primary' type="Appointment" />
                </div>
            </nav>

        </>
    )
}
