import React, { useState, useEffect } from 'react'
import Footer from '../components/Footer'
import Legend from '../components/Legend'
import Navbar from '../components/Navbar'
import Spinner from '../components/Spinner'
import TreatmentItemCard from '../components/TreatmentItemCard'
import { TextField } from '@mui/material'
import { treatmentData } from '../data/treatmentData'

export default function Treatments () {
    var items = treatmentData.map((cat) => {
        var list = []
        for (var i = 0; i < cat.treatments.length; i++) {
            var li = {
                category: cat.category,
                name: cat.treatments[i].name,
                description: cat.treatments[i].description,
                image: cat.treatments[i].image
            }
            list.push(li)
        }
        return list
    })

    items = items[0].concat(items[1])

    const [searchInput, setSearchInput] = useState('')

    const handleSearchInput = (e) => {
        var lowerCase = e.target.value.toLowerCase();
        setSearchInput(lowerCase)
    }


    return (
        <>
            <Spinner />
            <Navbar />
            <Legend pageName1="Our Treatments" pageName2="Treatments" />

            {/* <!-- Treatment Start --> */}
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row g-5 mb-5">
                        <div className="col-lg-12">
                            <div className="section-title mb-5">
                                <h5 className="position-relative d-inline-block text-primary text-uppercase">Our Treatments</h5>
                                <h1 className="display-5 mb-0">We Offer The Best Quality Treatments</h1>
                            </div>

                            <div className="row mb-5">
                                <TextField
                                    id="outlined-basic"
                                    onChange={handleSearchInput}
                                    variant="outlined"
                                    fullWidth
                                    label="Search"
                                />
                            </div>
                            <div className="row g-3 justify-center">
                                {

                                    items.filter((item) => {
                                        if (searchInput === '') {
                                            return item;
                                        } else {
                                            if (item.name) {
                                                var lowerCase = item.name.toLowerCase()
                                                if (lowerCase.includes(searchInput)) {
                                                    return item;
                                                }
                                            }
                                        }
                                    }).map((item, index) => {

                                        return <TreatmentItemCard item={item} key={index} />
                                    })
                                }
                            </div>



                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Treatment End --> */}

            <Footer />
        </>
    )
}
